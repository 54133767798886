import { enUS } from '@mui/material/locale';
import { createTheme, responsiveFontSizes } from '@mui/material/styles';
import { merge } from 'lodash';
import MuiAppBar from './components/MuiAppBar';
import MuiPalette from './palette';
import MuiTypography from './typography';

interface ThemeOptionsProps {
  components?: any;
  typography?: Record<string, any>;
  palette?: Record<string, any>;
}

export const THEMES = {
  name: 'WEB_APP',
  grey: '#c5c5c5',
};

const ThemeOptions: ThemeOptionsProps = {
  typography: MuiTypography,
  palette: MuiPalette,
  components: {
    MuiAppBar
  },
};

const theme = createTheme(merge(ThemeOptions), { direction: 'ltr' }, enUS);

export const themeWithLanguage = responsiveFontSizes(theme);
export default responsiveFontSizes(theme);
