import { configureStore } from '@reduxjs/toolkit';
import navigationSlice from '../components/navigation/navigation.slice';
import seoSlice from '../components/seo/seo.slice';
import homeSlice from '../views/home/home.slice';

const store = configureStore({
  reducer: {
    home: homeSlice,
    navigation: navigationSlice,
    seo: seoSlice,
  },
});

export default store;

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
