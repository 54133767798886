import '@fontsource/open-sans';
import MuiPalette from './palette';

/*  Supported variables:
    Weights: [300,400,500,600,700,800]
    Styles: [italic,normal]
    Supported subsets: [cyrillic,cyrillic-ext,greek,greek-ext,hebrew,latin,latin-ext,vietnamese]
 */

const MuiTypography = {
  h1: {
    fontFamily: 'Open Sans',
    letterSpacing: '-4px',
    color: MuiPalette.text.primary,
  },
};

export default MuiTypography;
