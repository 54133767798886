import { createSlice } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';

type listItemType = {
  id: number;
  title: string;
  description: string;
  slug: string;
  pathname: string;
};

type initialStateType = {
  list: listItemType[] | null;
};

const initialState: initialStateType = {
  list: [
    {
      id: 0,
      title: 'Home',
      description: 'Custom Web Application Development Services',
      slug: 'home',
      pathname: '/',
    },
  ],
};

export const navigationSlice = createSlice({
  name: 'navigation',
  initialState,
  reducers: {},
});

// Action creators are generated for each case reducer function
// export const {} = navigationSlice.actions;

export const useNavigation = () => {
  const navigation = useSelector((state: any) => state.navigation);

  return {
    navigation,
  };
};

export default navigationSlice.reducer;
