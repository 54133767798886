import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './views/App';
import reportWebVitals from './reportWebVitals';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import analytics from './utils/analytics';

if (process.env.REACT_APP_GOOGLE_ANALITCS_ID) {
  analytics.init(process.env.REACT_APP_GOOGLE_ANALITCS_ID as string);
}

Sentry.init({
  dsn: 'https://9d3613d756d448cbbeab46d1d51c6e47@o4504174247411712.ingest.sentry.io/4504174249050112',
  integrations: [new BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
