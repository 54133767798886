import { ThemeProvider } from '@mui/material';
import { HelmetProvider } from 'react-helmet-async';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import './app/App.css';
import Routes from '../components/Routes';

import store from '../store';
import { themeWithLanguage } from '../theme';

const App = () => {
  return (
    <Provider store={store}>
      <ThemeProvider theme={themeWithLanguage}>
        <HelmetProvider>
          <BrowserRouter>
            <Routes />
          </BrowserRouter>
        </HelmetProvider>
      </ThemeProvider>
    </Provider>
  );
};

export default App;
