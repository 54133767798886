import { createSlice } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import SLUGS from 'SLUGS';
import { ValueOf } from 'type-fest';

type PathnameSlugs = ValueOf<typeof SLUGS>;

export type SeoItemType = {
  title: string;
  description: string;
  slug: PathnameSlugs;
};

type initialStateType = Partial<Record<PathnameSlugs, SeoItemType>>;

const initialState: initialStateType = {
  app: {
    title: 'Weblab',
    description: '',
    slug: 'app',
  },
  home: {
    title: 'Custom web application development',
    description: 'Custom web application development',
    slug: 'home',
  },
  maintenance: {
    title: 'Custom web application development',
    description: 'Custom web application development',
    slug: 'maintenance',
  },
};

export const seoSlice = createSlice({
  name: 'seo',
  initialState,
  reducers: {
    update: (state, action) => {
      // todo
    },
  },
});

// Action creators are generated for each case reducer function
// export const {} = seoSlice.actions;

export const useSeo = () => {
  const seo = useSelector((state: any) => state.seo);

  return {
    seo,
  };
};

export default seoSlice.reducer;
