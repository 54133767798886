import React, { FC } from 'react';
import { Route, Routes as RoutesDom } from 'react-router-dom';
import useGoogleAnalytics from '../hooks/useGoogleAnalytics';

import Layout from '../layout';

const Home = React.lazy(() => import('../views/Home'));
const Maintenance = React.lazy(() => import('../views/Maintenance'));

type Props = {};

const Routes: FC<Props> = () => {
  useGoogleAnalytics();

  return (
    <>
      {process.env.REACT_APP_MAINTENANCE === 'true' && (
        <RoutesDom>
          <Route
            path="/"
            element={
              <React.Suspense fallback={<>...</>}>
                <Maintenance />
              </React.Suspense>
            }
          />
        </RoutesDom>
      )} 

      {process.env.REACT_APP_MAINTENANCE === 'false' && (
        <RoutesDom>
          <Route path="/" element={<Layout />}>
            <Route index element={<Home />} />
          </Route>
          <Route path="*" element={<>Nomatch</>} />
        </RoutesDom>
      )}
    </>
  );
};

export default Routes;
