import '@fontsource/open-sans';
import { styled } from '@mui/material';
import { FC } from 'react';
import logo from './weblab_logo.webp';

const StyledComponent = styled('div')({
  fontFamily: 'Open Sans',
  letterSpacing: '-2px',
  //color: MuiPalette.text.primary,
  color: '#fff',
  fontSize: '1.8rem',
  fontWeight: 600,
  // textTransform: 'uppercase',
});

type Props = {};

const Logo: FC<Props> = () => {
  return (
    <StyledComponent>
      <img src={logo} alt="WEBLAB" title="WEBLAB" width={300} /> 
    </StyledComponent>
  );
};

export default Logo;
