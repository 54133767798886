import { ComponentsProps, ComponentsVariants } from "@mui/material/styles";

const MuiAppBar: { defaultProps: ComponentsProps['MuiAppBar'], variants: ComponentsVariants['MuiAppBar']} = {
    defaultProps: {
        style: {
            background: 'transparent',
            boxShadow: 'none'
        },
    },
    variants: [
        {
            props: { 

             },
            style: { 
                //
             },
        }
    ]
}

export default MuiAppBar


