import ReactGA from 'react-ga4';

//  https://www.npmjs.com/package/react-ga4
//  https://support.google.com/analytics/answer/9216061?hl=sk&utm_id=ad
//  https://medium.com/@vergil333/google-analytics-4-in-react-a250005c2455

const analytics = {
  init(TRACKING_ID: string) {
    ReactGA.initialize(TRACKING_ID);
  },

  sendEvent(hitType: string, page: string) {
    ReactGA.send({ hitType: hitType, page: page });
  },

  event(category: string, action: string, label: string) {
    ReactGA.event({ category: category, action: action, label: label });
  },
};

export default analytics;
